#gallery-item {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: .5s ease;
}

#gallery-item img:hover {
    opacity: 0.3;
    cursor: pointer;
}

.spinner-container {
    position: relative;
    left: 50%;
    left: calc(50% - 25px);
    top: 50%;
    top: calc(50% - 25px);
}

.spinner-container .thumb-spinner {
    position: absolute;
    height: 30px;
    width: 30px;
    margin: 0px auto;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left: 6px solid rgba(0,174,239,.15);
    border-right: 6px solid rgba(0,174,239,.15);
    border-bottom: 6px solid rgba(0,174,239,.15);
    border-top: 6px solid rgba(0,174,239,.8);
    border-radius: 100%;
 }
 
 @-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(359deg);}
 }

 @-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(359deg);}
 }

 @-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to {-o-transform: rotate(359deg);}
 }

 @keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
 }