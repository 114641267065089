#close {
    float: right;
    border: 0;
}

#lightbox-off {
    display: none;
    position: fixed;
    background: rgba(0,0,0,0.75);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
}

#lightbox-on {
    overflow: scroll;
    position: fixed;
    background: rgba(0,0,0,0.75);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: grid;
    align-items: center;
    justify-items: center;
}

#lightbox-inner {
    max-width: 1000px;
    padding: 20px;
}

#lightbox-img {
    width: 100%;
}

#spinner {
    height: 200px;
    width: 300px;
}

.spinner {
    position: absolute;
    left: 50%;
    left: calc(50% - 30px);
    top: 50%;
    top: calc(50% - 30px);
    height: 60px;
    width: 60px;
    margin: 0px auto;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left: 6px solid rgba(0,174,239,.15);
    border-right: 6px solid rgba(0,174,239,.15);
    border-bottom: 6px solid rgba(0,174,239,.15);
    border-top: 6px solid rgba(0,174,239,.8);
    border-radius: 100%;
 }
 
 @-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(359deg);}
 }

 @-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(359deg);}
 }

 @-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to {-o-transform: rotate(359deg);}
 }

 @keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
 }